import { Tenant } from '../context/AuthProvider'
import { Employee } from './Employee'
import { Farm } from './Farm'
import { EmployeeJobType, JournalDailyWorkPaymentStatus } from './Journal'

export type EmployeeWorkDetailPayroll = {
  employee: Employee
  journalDailyWorkDetails: JournalDailyWorkDetailPayroll[]
  totalPayment: number
  accountNumber: string | null
  paidDate?: string | null
}

export type JournalDailyWorkPayroll = {
  id: number
  journalDailyWorkDate: string
  farm: Farm
  tenant: Tenant
}

export type JournalDailyWorkDetailPayroll = {
  journalDailyWork: JournalDailyWorkPayroll
  dayPayment: number
  id: number
  journalDailyWorkId: number
  employee: Employee
  employeeJobTypeId: number
  employeeJobType: EmployeeJobType
  jobQuantity: number
  jobPrice: number
  paid: boolean
  payrollDetailId: number
}

export type PaymentTypeTotal = {
  paymentType: string
  totalPayment: number
}

export type PayrollMaster = {
  id: number
  payrollDate: Date
  payrolls: Payroll[]
  total: number
}

export type Payroll = {
  id: number
  payrollMasterId: number
  total: number
  tenant?: Tenant
}

export type GetPayrollDetailsResponse = {
  employeeWorkDetails: EmployeeWorkDetailPayroll[]
  totalPayrollPayment: number
  paymentTypeTotals: PaymentTypeTotal[]
  farms: Farm[]
  tenants: Tenant[]
  employeeJobTypes: EmployeeJobType[]
  payrollStatus: PayrollStatus
  approvers: Approver[]
}

export type JournalDailyWorkRequest = {
  paymentStatus: JournalDailyWorkPaymentStatus
  tenantId: number | null
  farmId: number | null
  employeeJobTypeIds: number[]
  paymentTypeId: number | null
  payrollMasterId: number | null
  fromDate: string | null
  toDate: string | null
}

export type PayrollDetailsRequest = {
  tenantId: number | null
  farmId: number | null
  employeeJobTypeIds: number[]
  paymentTypeId: number | null
  payrollMasterId: number | null
  fromDate: string | null
  toDate: string | null
}

export enum PayrollStatus {
  Created = 1,
  Approved = 2,
  Paid = 3,
}

export type Approver = {
  id: number
  firstName: string
  lastName: string
  approved: boolean
}