import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid2'
import Fab from '@mui/material/Fab'
import Iconify from '../../components/iconify'
import React from 'react'
import { Farm } from '../../types/Farm'
import { useNavigate } from 'react-router-dom'

type FarmCardProps = {
  farm: Farm
}

type ActionButtonInfo = {
  text: string
  icon: string
  onClick?: () => void
  color: 'default' | 'inherit' | 'primary' | 'secondary'
}

const FarmCard: React.FC<FarmCardProps> = ({ farm }) => {
  const { imageUrl, name } = farm
  const navigate = useNavigate()

  const handleReportClick = () => {
    navigate("/reporte-trabajo/agregar", {
      state: { selectedFarm: farm },
    })
  }

  const handleViewClick = () => {
    console.log(`Ver - Farm name: ${name}`)
  }

  const renderTitle = (
    <Link
      color='inherit'
      variant='subtitle1'
      underline='hover'
      sx={{       
        overflow: 'hidden',
        WebkitLineClamp: 2,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
      }}
    >
      {name}
    </Link>
  )

  const renderInfo = (
    <Stack
      direction='row'  
      flexWrap='wrap'    
      spacing={1.5}
      justifyContent='flex-start'
      alignItems='flex-start'
      sx={{ mt: 2, ml: -2,
        '& > :not(style)~:not(style)': {
          marginLeft: 0, // Anula el margen izquierdo para todos los elementos excepto el primero
        }
       }}
    >
      {[
        {
          text: 'Reporte trabajo',
          icon: 'mdi:post-it-note-add',
          onClick: handleReportClick,
          color: 'inherit',
        } as ActionButtonInfo,
        {
          text: 'Ver',
          icon: 'eva:eye-fill',
          onClick: handleViewClick,
          color: 'inherit',          
        } as ActionButtonInfo,
      ].map((info, _index) => (
        <Stack key={_index} >
          <Fab variant='extended' size='small'  color={info.color} onClick={info.onClick} sx={{ mt: 1, ml: 1 }}>
            <Iconify width={16} icon={info.icon} sx={{ mr: 1 }} />
            {info.text}
          </Fab>
        </Stack>
      ))}
    </Stack>
  )

  const renderCover = (
    <Box
      component='img'
      alt={name}
      src={imageUrl}
      sx={{
        top: 0,
        width: 1,
        height: 1,
        objectFit: 'cover',
        position: 'absolute',
      }}
    />
  )

  // const renderDate = (
  //   <Typography
  //     variant='caption'
  //     component='div'
  //     sx={{
  //       mb: 2,
  //       color: 'text.disabled',
  //     }}
  //   >
  //     Ultimo reporte: {fDate(lastJournalReportDate)}
  //   </Typography>
  // )

  return (
    <Grid size={{ xs: 12, sm: 6, md: 3 }}>
      <Card>
        <Box
          sx={{
            position: 'relative',
            pt: 'calc(100% * 3 / 4)',
          }}
        >
          {renderCover}
        </Box>

        <Box
          sx={{
            p: (theme) => theme.spacing(4, 3, 3, 3),
          }}
        >        
          {renderTitle}
          {renderInfo}
        </Box>
      </Card>
    </Grid>
  )
}

export default FarmCard
