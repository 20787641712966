import { memo, forwardRef, ReactNode, ForwardedRef } from 'react'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/system'
import SimpleBar from 'simplebar-react'

interface ScrollbarProps {
  children: ReactNode
  sx?: SxProps<Theme>
  [key: string]: any
}

const Scrollbar = forwardRef(function Scrollbar(
  { children, sx, ...other }: ScrollbarProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )

  if (mobile) {
    return (
      <Box
        ref={ref}
        sx={{
          overflow: 'auto',
          ...(sx as Record<string, unknown>),
        }}
        {...other}
      >
        {children}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <SimpleBar
        scrollableNodeProps={{
          ref,
        }}
        clickOnTrack={false}
        style={{ maxHeight: '100%' }}
        {...other}
      >
        {children}
      </SimpleBar>
    </Box>
  )
})

export default memo(Scrollbar)
