import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Approver,
  EmployeeWorkDetailPayroll,
  GetPayrollDetailsResponse,
  PayrollDetailsRequest,
  PayrollStatus,
} from '../../types/Payroll'
import { Tenant } from '../../context/AuthProvider'
import { Farm } from '../../types/Farm'
import { getFullName, PaymentType } from '../../types/Employee'
import {
  fDateShort,
  formatDateTimeCustom,
  getCurrentUTCDateTime,
} from '../../utils/format-time'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { AxiosError } from 'axios'
import { useSnackbar } from '../../context/SnackbarProvider'
import ReportSkeletons from './ReportSkeletons'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import { fNumber } from '../../utils/format-number'
import Grid from '@mui/material/Grid2'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import PayrollFilters from './PayrollFilters'
import { useAuth } from '../../context/AuthProvider'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import { green } from '@mui/material/colors'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PayrollEmployeeWorkDetails from './PayrollEmployeeWorkDetails'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { usePayrollPay } from '../../context/PayrollPayProvider'
import { useNavigate } from 'react-router-dom'
import { getLocalStorageItem } from '../../utils/localStorageUtils'
import { payrollFiltersConstants } from './PayrollFiltersConstants'
import Loading from '../../components/Loading/Loading'
import Backdrop from '@mui/material/Backdrop'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { Box } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import DynamicDialog from '../../components/DynamicDialog'

const MIN_LOADING_TIME = 500

type MenuOption = {
  name: string
  isEnable: boolean
  action: () => void
}

type PayEmployeesRequest = {
  paidByUserEmail: string
  payrollMasterId: number
  employeePayrollDetails: EmployeePayrollDetails[]
}

type EmployeePayrollDetails = {
  employeeId: number
  payrollDetailIds: number[]
}

interface PayrollPayIncludedProps {
  payrollId: number
  onApprovePayroll: () => void
  onClosePayroll: () => void
  onDeletePayroll: () => void
  showApprovePayrollFeature: boolean
  setShowApprovePayrollFeature: (showApproveFeature: boolean) => void
  payrollApprovedByCurrentUser: boolean
  payrollApprovedByAllApprovers: boolean
  setPayrollOptionsEnabled: (enabled: boolean) => void
}

const PayrollPayIncluded: FC<PayrollPayIncludedProps> = ({
  payrollId,
  setShowApprovePayrollFeature,
  payrollApprovedByCurrentUser,
  payrollApprovedByAllApprovers,
  setPayrollOptionsEnabled,
}) => {
  const { showError, showSuccess } = useSnackbar()
  const [payrollDetails, setPayrollDetails] = useState<GetPayrollDetailsResponse>()
  const [paidEmployees, setPaidEmployees] = useState<EmployeeWorkDetailPayroll[]>([])
  const [unpaidEmployees, setUnpaidEmployees] = useState<EmployeeWorkDetailPayroll[] >([])
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
  const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null)
  const [selectedJobTypeIds, setSelectedJobTypeIds] = useState<number[]>([])
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [checkboxEmployeeStates, setCheckboxEmployeeStates] = useState<boolean[]>([])
  const [selectedEmployeeWorkDetails, setSelectedEmployeeWorkDetails] = useState<EmployeeWorkDetailPayroll[]>([])
  const [payrollApprovers, setPayrollApprovers] = useState<Approver[]>([])
  const { auth } = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openPayrollDetailMenu = Boolean(anchorEl)
  const [openPayrollDetailPopup, setOpenPayrollDetailPopup] = useState(false)
  const [selectedEmployeeWorkDetail, setSelectedEmployeeWorkDetail] = useState<EmployeeWorkDetailPayroll | null>(null)
  const { payrollUpdated } = usePayrollPay()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const handleDateClick = (journalDailyWorkId: number) => {
    navigate(`/reporte-trabajo/detalles/${journalDailyWorkId}`, {
      state: { currentJournalDailyWorkId: journalDailyWorkId },
    })
  }

  const payrollDetailMenuOptions: MenuOption[] = [
    {
      name: 'Editar',
      isEnable: true,
      action: () => {
        setOpenPayrollDetailPopup(true)
      } 
    },
    {
      name: 'Eliminar Pago',
      isEnable: false,
      action: () => {
        setOpenDialog(true)     
      }
    }
  ]

  const handleOnConfirmDelete = async () => {
    
    if (selectedEmployeeWorkDetail !== null) {
      await RemoveEmployeePayment(selectedEmployeeWorkDetail)     
      setOpenDialog(false)
  }
}
  const handleOnCloseDelete = () => {
    setOpenDialog(false)
}

const dialogAcceptButton = {
  text: 'Eliminar',
  action: handleOnConfirmDelete,
}

const dialogCancelButton = {
  text: 'Cancelar',
  action: handleOnCloseDelete,
}

  useEffect(() => {
    const paid: EmployeeWorkDetailPayroll[] = []
    const unpaid: EmployeeWorkDetailPayroll[] = []

    payrollDetails?.employeeWorkDetails.forEach((employee) => {
      if (employee.paidDate) {
        paid.push(employee)
      } else {
        unpaid.push(employee)
      }
    })

    setPaidEmployees(paid)
    setUnpaidEmployees(unpaid)    
    setCheckboxEmployeeStates(unpaid.map(() => false))
    setSelectedEmployeeWorkDetails([])
  }, [payrollDetails])

  useEffect(() => {
    setPayrollApprovers((prevApprovers) => {
      return prevApprovers.map((approver) => {
        if (approver.id === auth.user?.userid || 0) {
          return {
            ...approver,
            approved: true,
          }
        }
        return approver
      })
    })

    if (payrollApprovedByAllApprovers) {
      setPayrollDetails((prevPayrollDetails) => {
        if (!prevPayrollDetails) return prevPayrollDetails
        return {
          ...prevPayrollDetails,
          payrollStatus: PayrollStatus.Approved,
        }
      })
    }
  }, [
    auth.user?.userid,
    payrollApprovedByAllApprovers,
    payrollApprovedByCurrentUser,
  ])

  const payrollDetailsRequest: PayrollDetailsRequest = useMemo(
    () => ({
      tenantId: selectedTenant ? selectedTenant.id : null,
      farmId: selectedFarm ? selectedFarm.id : null,
      employeeJobTypeIds: selectedJobTypeIds,
      paymentTypeId: selectedPaymentType ? selectedPaymentType.id : null,
      payrollMasterId: payrollId,
      fromDate: null,
      toDate: null,
    }),
    [
      selectedTenant,
      selectedFarm,
      selectedJobTypeIds,
      selectedPaymentType,
      payrollId,
    ]
  )

  const handleCheckboxEmployeeChange = (
    index: number,
    report: EmployeeWorkDetailPayroll
  ) => {
    if (!report.paidDate) {
      const updatedCheckboxStates = [...checkboxEmployeeStates]
      updatedCheckboxStates[index] = !updatedCheckboxStates[index]
      setCheckboxEmployeeStates(updatedCheckboxStates)

      const updatedSelectedEmployeeWorkDetails = updatedCheckboxStates
        .map((selected, idx) => (selected ? unpaidEmployees[idx] : null))
        .filter(Boolean) as EmployeeWorkDetailPayroll[]

      setSelectedEmployeeWorkDetails(updatedSelectedEmployeeWorkDetails)
    }
  }

  const isAnyCheckboxEmployeeSelected = checkboxEmployeeStates.some(
    (state, index) => state && !unpaidEmployees[index]?.paidDate
  )
  const areAllCheckboxEmployeesSelected =
    payrollDetails?.payrollStatus === PayrollStatus.Approved &&
    unpaidEmployees.every((report, index) => {
      return report.paidDate || checkboxEmployeeStates[index]
    })

  const handleSelectAllCheckboxEmployees = () => {
    const updatedCheckboxStates = checkboxEmployeeStates.map((state, index) =>
      !unpaidEmployees[index]?.paidDate
        ? !areAllCheckboxEmployeesSelected
        : state
    )
    setCheckboxEmployeeStates(updatedCheckboxStates)
    setSelectedEmployeeWorkDetails(
      updatedCheckboxStates
        .map((selected, index) => (selected ? unpaidEmployees[index] : null))
        .filter(Boolean) as EmployeeWorkDetailPayroll[]
    )
  }

  useEffect(() => {
    setPayrollOptionsEnabled(selectedEmployeeWorkDetails.length === 0 || unpaidEmployees.length === 0)
  }, [selectedEmployeeWorkDetails, setPayrollOptionsEnabled, unpaidEmployees])

  const GetPayrollDetails = useCallback(
    async (controller: AbortController) => {
      setIsLoading(true)
      try {
        const fetchStart = Date.now()

        const responsePromise = axiosPrivate.post(
          endpoints.getPayrollDetails,
          payrollDetailsRequest,
          { signal: controller.signal }
        )

        const [response] = await Promise.all([
          responsePromise,
          new Promise((resolve) =>
            setTimeout(resolve, MIN_LOADING_TIME - (Date.now() - fetchStart))
          ), // Ensure a minimum loading time
        ])

        const payrollResponse = response.data as GetPayrollDetailsResponse
        setPayrollDetails(payrollResponse)
        setPayrollApprovers(payrollResponse.approvers || [])
        setShowApprovePayrollFeature(
          payrollResponse.payrollStatus === PayrollStatus.Created &&
            (auth.user?.isApprover ?? false) &&
            payrollResponse.approvers.some(
              (approver: Approver) =>
                approver.approved === false && approver.id === auth.user?.userid
            )
        )
        const unpaidEmployees = payrollResponse.employeeWorkDetails.map(detail => detail.paidDate === null)
        setCheckboxEmployeeStates(unpaidEmployees)
        setIsLoading(false)
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          setIsLoading(false)
          showError('Error al obtener los datos de la planilla')
        }
      }
    },
    [
      auth.user?.isApprover,
      auth.user?.userid,
      payrollDetailsRequest,
      setPayrollApprovers,
      setShowApprovePayrollFeature,
      showError,
    ]
  )

  useEffect(() => {
    const savedTenant = getLocalStorageItem(
      payrollFiltersConstants.selectedTenantStorageKey
    )
    const matchedTenant = savedTenant ? JSON.parse(savedTenant) : null
    if (matchedTenant) {
      setSelectedTenant((prev) =>
        prev?.id !== matchedTenant.id ? matchedTenant : prev
      )
    }

    const savedFarm = getLocalStorageItem(
      payrollFiltersConstants.selectedFarmStorageKey
    )
    const matchedFarm = savedFarm ? JSON.parse(savedFarm) : null
    if (matchedFarm) {
      setSelectedFarm((prev) =>
        prev?.id !== matchedFarm.id ? matchedFarm : prev
      )
    }

    const savedPaymentType = getLocalStorageItem(
      payrollFiltersConstants.selectedPaymentTypeStorageKey
    )
    const matchedPaymentType = savedPaymentType
      ? JSON.parse(savedPaymentType)
      : null
    if (matchedPaymentType) {
      setSelectedPaymentType((prev) =>
        prev?.id !== matchedPaymentType.id ? matchedPaymentType : prev
      )
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    GetPayrollDetails(controller)
    return () => {
      controller.abort()
    }
  }, [
    selectedTenant,
    selectedFarm,
    selectedJobTypeIds,
    selectedPaymentType,
    GetPayrollDetails,
    payrollUpdated,
  ])

  const handleClickPayrollDetailMenu = (
    event: React.MouseEvent<HTMLElement>,
    employeeWorkDetail: EmployeeWorkDetailPayroll
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedEmployeeWorkDetail(employeeWorkDetail)
  }

  const handleCloseReportMenu = () => {
    setAnchorEl(null)
  }

  const onClosePayrollEmployeeWorkDetailsDialog = () => {
    setOpenPayrollDetailPopup(false)
  }

  const UpdateLocalPayrollDetailsPaidDate = (
    employeeIds: number[], paidDateValue?: string | null
  ) => {
    setPayrollDetails(
      (prevPayrolDetails) =>
        prevPayrolDetails && {
          ...prevPayrolDetails,
          employeeWorkDetails: prevPayrolDetails.employeeWorkDetails.map(
            (report) => ({
              ...report,
              paidDate: employeeIds.includes(report.employee.id)
              ? paidDateValue
              : report.paidDate,
            })
          ),
        }
    )   
  }

  const RemoveEmployeePayment = async (employeeWorkDetail: EmployeeWorkDetailPayroll) => {
    setIsBackdropOpen(true)
    const controller = new AbortController()

    const SendRemoveEmployeePaymentRequest = async () => {
      try {
        const url = endpoints.removeEmployeePayment
          .replace('{employeeId}', employeeWorkDetail.employee.id.toString())
          .replace('{payrollMasterId}', payrollId.toString())
          .replace('{deletedByUserId}', auth.user?.userid.toString() || '0')

          const response = await axiosPrivate.delete(url, {
            signal: controller.signal,
          })        

        if (response.status === 200) {
          showSuccess('El pago del empleado se ha eliminado exitosamente!')
          UpdateLocalPayrollDetailsPaidDate([employeeWorkDetail.employee.id], null)
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {
          showError(
            'Error al intentar actualizar como pagados los empleados seleccionados ' +
              error?.response?.data?.detail
          )
        }
      } finally {
        setIsBackdropOpen(false)
      }
    }

    SendRemoveEmployeePaymentRequest()
  }

  const PayEmployees = async (payEmployeesRequest: PayEmployeesRequest) => {
    setIsBackdropOpen(true)
    const controller = new AbortController()
    const successMessage =
      payEmployeesRequest.employeePayrollDetails.length > 1
        ? 'Los empleados seleccionados se han marcado como pagados exitosamente!'
        : 'El empleado seleccionado se ha marcado como pagado exitosamente!'

    const SendPayPayrollDetailsRequest = async () => {
      try {
        const response = await axiosPrivate.post(
          endpoints.addEmployeePayment,
          payEmployeesRequest,
          { signal: controller.signal }
        )

        if (response.status === 200) {
          showSuccess(successMessage)
          UpdateLocalPayrollDetailsPaidDate(payEmployeesRequest.employeePayrollDetails.map((detail) => detail.employeeId), getCurrentUTCDateTime())
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {
          showError(
            'Error al intentar actualizar como pagados los empleados seleccionados ' +
              error?.response?.data?.detail
          )
        }
      } finally {
        setIsBackdropOpen(false)
      }
    }

    SendPayPayrollDetailsRequest()
  }

  const handlePayrollPayment = async () => {
    const payPayrollDetailsRequest: PayEmployeesRequest = {
      paidByUserEmail: auth.user?.email || '',
      payrollMasterId: payrollId,
      employeePayrollDetails: selectedEmployeeWorkDetails.map(
        (employeeWorkDetail) => ({
          employeeId: employeeWorkDetail.employee.id,
          payrollDetailIds: employeeWorkDetail.journalDailyWorkDetails.map(
            (detail) => detail.payrollDetailId
          ),
        })
      ),
    }

    await PayEmployees(payPayrollDetailsRequest)
  }

  const isReportSelectable = (report: EmployeeWorkDetailPayroll) =>
    !report.paidDate && payrollDetails?.payrollStatus === PayrollStatus.Approved

  const displayEmpoyeeSelection =
    payrollDetails?.payrollStatus === PayrollStatus.Approved &&
    checkboxEmployeeStates.length > 0 &&
    unpaidEmployees.length > 0

    const renderEmployeeWorkDetails = ( employees: EmployeeWorkDetailPayroll[]) => {
      return employees.map((report, index) => (
        <Grid size={12} container spacing={1} sx={{ ml: 1 }} key={index}>
          <Grid size={12}>
            <Grid size={12} container>
              <Grid
                size={11}
                container
                onClick={() =>
                  isReportSelectable(report) &&
                  handleCheckboxEmployeeChange(index, report)
                }
                sx={isReportSelectable(report) ? { cursor: 'pointer' } : {}}
              >
                <Grid size={12} container alignItems='center'>
                  {isReportSelectable(report) && (
                    <Checkbox
                      size='small'
                      sx={{ m: 0, p: 0 }}
                      checked={checkboxEmployeeStates[index] || false}
                      onChange={() =>
                        handleCheckboxEmployeeChange(index, report)
                      }
                    />
                  )}
                  <Typography variant='h6' sx={{ ml: 1 }}>
                    {getFullName(report.employee)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                size={1}
                justifyContent='flex-end'
                alignItems='center'
              >
                <IconButton
                  aria-label='more'
                  id='long-button'
                  size='small'
                  aria-controls={
                    openPayrollDetailMenu ? 'long-menu' : undefined
                  }
                  aria-expanded={openPayrollDetailMenu ? 'true' : undefined}
                  aria-haspopup='true'
                  onClick={(e) => handleClickPayrollDetailMenu(e, report)}
                >
                  <MoreVertIcon />
                </IconButton>
              </Grid>
              <Menu
                id='long-menu'
                anchorEl={anchorEl}
                open={openPayrollDetailMenu}
                onClose={handleCloseReportMenu}
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
              >
                {payrollDetailMenuOptions.map((option) => (
                  <MenuItem
                    key={option.name}
                    onClick={() => {
                      handleCloseReportMenu()
                      selectedEmployeeWorkDetail !== null && option.action()
                    }}
                    disabled={
                      option.name === 'Editar'
                        ? selectedEmployeeWorkDetail?.paidDate !== null
                        : option.name === 'Eliminar Pago'
                        ? selectedEmployeeWorkDetail?.paidDate === null
                        : false
                    }
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: { xs: 'auto', md: 650 } }}
                size='small'
                aria-label='a dense table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Trabajo</TableCell>
                    <TableCell align='right'>Cantidad</TableCell>
                    <TableCell align='right'>Precio</TableCell>
                    <TableCell align='right'>SubTotal</TableCell>
                    <TableCell>Finca</TableCell>
                    <TableCell>Perfil</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.journalDailyWorkDetails.map((detail) => (
                    <TableRow
                      key={detail.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell>
                        <Button
                          onClick={() => {
                            handleDateClick(detail.journalDailyWork.id)
                          }}
                        >
                          {fDateShort(
                            detail.journalDailyWork.journalDailyWorkDate
                          )}
                        </Button>
                      </TableCell>
                      <TableCell>{detail.employeeJobType.name}</TableCell>
                      <TableCell align='right'>
                        {fNumber(detail.jobQuantity)}
                      </TableCell>
                      <TableCell align='right'>
                        {fNumber(detail.jobPrice)}
                      </TableCell>
                      <TableCell align='right'>
                        {fNumber(detail.dayPayment)}
                      </TableCell>
                      <TableCell>{detail.journalDailyWork.farm.name}</TableCell>
                      <TableCell>
                        {detail.journalDailyWork.tenant.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container spacing={1}>
              <Grid size={{ xs: 12, md: 'auto' }}>
                <Typography variant='body2' fontWeight='bold' sx={{ mx: 1 }}>
                  Total = {fNumber(report.totalPayment)}
                </Typography>
              </Grid>

              <Grid size={{ xs: 12, md: 'auto' }}>
                <Typography variant='body2' sx={{ mx: 1 }}>
                  Pago en {report.employee.paymentTypeDescription}{' '}
                  {report.accountNumber ? `(${report.accountNumber})` : ''}
                </Typography>
              </Grid>
            </Grid>

            {report.paidDate && (
              <Grid container spacing={1}>
                <Grid size={'auto'}>
                  <Typography variant='body2' sx={{ mx: 1, background: '#D0E8C5' }}>
                    Pagado: {formatDateTimeCustom(report.paidDate)}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid size={12} container sx={{ mx: 1, mt: 2, mb: 2 }}>
              <Divider style={{ width: '100%' }} />
            </Grid>
          </Grid>
        </Grid>
      ))
    }

  return (
    <>
      <PayrollFilters
        tenants={payrollDetails?.tenants || []}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        farms={payrollDetails?.farms || []}
        selectedFarm={selectedFarm}
        setSelectedFarm={setSelectedFarm}
        employeeJobTypes={payrollDetails?.employeeJobTypes || []}
        selectedJobTypeIds={selectedJobTypeIds}
        setSelectedJobTypeIds={setSelectedJobTypeIds}
        selectedPaymentType={selectedPaymentType}
        setSelectedPaymentType={setSelectedPaymentType}
      />

      {payrollApprovers.some((approver) => !approver.approved) && (
        <Grid size={{ xs: 12, md: 8 }} sx={{ mt: 0, mb: 2, }}>
          <Grid size={12}>
            <Card>
              <CardContent sx={{ background: '#ffffb7' }}>
                <Typography sx={{ ml: 1 }} variant='h6'>
                  Pendiente de aprobar por:
                </Typography>
                <List dense={true} sx={{mb: 0, pb:0}}>
                  {payrollApprovers
                    .filter((approver) => !approver.approved)
                    .map((approver) => (
                      <ListItem key={approver.id} sx={{my:0, py:0}}>
                        <ListItemText
                          primary={`- ${approver.firstName} ${approver.lastName}`}
                        />
                      </ListItem>
                    ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {displayEmpoyeeSelection && (
        <Button
          variant='contained'
          color={areAllCheckboxEmployeesSelected ? 'warning' : 'inherit'}
          onClick={handleSelectAllCheckboxEmployees}
          sx={{ ml: 2, mb: 2 }}
        >
          {areAllCheckboxEmployeesSelected
            ? 'Deseleccionar todos'
            : 'Seleccionar todos'}
        </Button>
      )}

      <Card>
        <CardContent>
          <Backdrop
            sx={{
              position: 'absolute',
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isBackdropOpen}
          >
            <Loading centered={true} />
          </Backdrop>

          {isLoading ? (
            <ReportSkeletons count={5} />
          ) : (
            <>
              <SimpleTreeView
                defaultExpandedItems={['unpaid-employees', 'paid-employees']}
              >
                <TreeItem
                  classes={{
                    content: 'Mui-selected Mui-focused',
                  }}
                  sx={{ mb: 1 }}
                  itemId='unpaid-employees'
                  label={
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`Pendientes de pago (${unpaidEmployees.length})`}</span>
                  }
                >
                  <Box sx={{ mb: 3 }} />
                  {renderEmployeeWorkDetails(unpaidEmployees)}
                </TreeItem>
                <TreeItem
                  classes={{
                    content: 'Mui-selected Mui-focused',
                  }}
                  itemId='paid-employees'
                  label={
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`Empleados pagados (${paidEmployees.length})`}</span>
                  }
                >
                  <Box sx={{ mb: 3 }} />
                  {renderEmployeeWorkDetails(paidEmployees)}
                </TreeItem>
              </SimpleTreeView>
            </>
          )}

          <Grid size={12} container sx={{ mx: 1, mt: 3, mb: 2 }}>
            <Divider style={{ width: '100%' }} />
          </Grid>

          <Grid size={12} container spacing={1} sx={{ ml: 1 }}>
            <TextField
              label='Total Planilla'
              value={fNumber(payrollDetails?.totalPayrollPayment)}
              fullWidth
              InputProps={{
                readOnly: true,
                style: { fontWeight: 'bold' },
              }}
            />
          </Grid>

          <Grid size={12} container spacing={1} sx={{ ml: 1, mt: 1 }}>
            {payrollDetails?.paymentTypeTotals.map((paymentTypeTotal) => (
              <Grid
                size={{ xs: 12, md: 6 }}
                key={paymentTypeTotal.paymentType}
                sx={{ mt: 1 }}
              >
                <TextField
                  label={`Total ${paymentTypeTotal.paymentType}`}
                  value={fNumber(paymentTypeTotal.totalPayment)}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    style: { fontWeight: 'bold' },
                  }}
                />
              </Grid>
            ))}
          </Grid>

          {payrollApprovers.some((approver) => approver.approved) && (
            <>
              <Grid size={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
                <Divider style={{ width: '100%' }} />
              </Grid>
              <Grid size={12} container spacing={1} sx={{ ml: 1 }}>
                <Grid size={12}>
                  <Typography variant='h6'>Planilla aprobada por</Typography>
                  <List dense={true}>
                    {payrollApprovers
                      .filter((approver) => approver.approved)
                      .map((approver) => (
                        <ListItem key={approver.id}>
                          <ListItemIcon sx={{ minWidth: 'auto' }}>
                            <CheckBoxIcon color='success' />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${approver.firstName} ${approver.lastName}`}
                            sx={{ ml: 1 }}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Grid>
              </Grid>
            </>
          )}

          <Grid size={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
            <Divider style={{ width: '100%' }} />
          </Grid>

          {isAnyCheckboxEmployeeSelected && (
            <Tooltip title='Pagar' enterTouchDelay={0}>
              <Fab
                color='primary'
                sx={{
                  position: 'fixed',
                  bottom: 16,
                  right: 16,
                  bgcolor: green[500],
                  '&:hover': { bgcolor: green[600] },
                }}
                onClick={handlePayrollPayment}
              >
                <PriceCheckIcon />
              </Fab>
            </Tooltip>
          )}
          <PayrollEmployeeWorkDetails
            open={openPayrollDetailPopup}
            onClose={onClosePayrollEmployeeWorkDetailsDialog}
            employeeWorkDetails={selectedEmployeeWorkDetail!}
            isDeleteAction={true}
            payrollId={payrollId}
          />
          <DynamicDialog
            openDialog={openDialog}
            dialogTitle='Confirmar eliminación'
            onCloseAction={handleOnCloseDelete}
            buttonAccept={dialogAcceptButton}
            buttonCancel={dialogCancelButton}
          >
            <DialogContentText id='generic-dialog-description'>
              ¿Realmente desea eliminar el pago para el empleado seleccionado?
            </DialogContentText>
          </DynamicDialog>
        </CardContent>
      </Card>
    </>
  )
}

export default PayrollPayIncluded
