import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { GridToolbarProps } from '@mui/x-data-grid'

interface AdditionalActionButton {
  action: () => void
  icon: React.ReactNode
  tooltipTitle: string
}

interface DataGridToolbarProps extends GridToolbarProps {
  numSelected?: number    
  onEdit?: () => void
  onDelete?: () => void
  additionalActionButton?: AdditionalActionButton
}

const DataGridToolbar: React.FC<DataGridToolbarProps> = ({
  numSelected = 0,    
  onEdit,
  onDelete,
  additionalActionButton,
}) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => theme.palette.action.selected,
        }),
      }}
    >
      {numSelected > 0 ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {onEdit && (
            <Tooltip title="Editar" sx={{ pl: 0 }}>
              <IconButton size="small" onClick={onEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title="Eliminar">
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {additionalActionButton && (
            <Tooltip title={additionalActionButton.tooltipTitle}>
              <IconButton onClick={additionalActionButton.action}>
                {additionalActionButton.icon}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ) : (
        null
      )}
    </Toolbar>
  )
}

export default DataGridToolbar