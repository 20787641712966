import { FC, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import { formatDateTimeCustom, formatFullDateWithDay } from '../../utils/format-time'
import Divider from '@mui/material/Divider'
import { Typography } from '@mui/material'
import { getFullName } from '../../types/Employee'
import { JournalDailyWorkProduct } from '../../types/Product'
import { fNumber } from '../../utils/format-number'
import { JournalDailyWork } from '../../types/Journal'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { useSnackbar } from '../../context/SnackbarProvider'
import { AxiosError } from 'axios'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import Iconify from '../../components/iconify'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import dayjs, { Dayjs } from 'dayjs'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Loading from '../../components/Loading/Loading'
import { useAuth } from '../../context/AuthProvider'
import DialogContentText from '@mui/material/DialogContentText'
import DynamicDialog from '../../components/DynamicDialog'

type MenuOption = {
  name: string
  isEnable: boolean
  action: () => void
}

interface DailyWorkReportViewProps {
  journalDailyWorkId: number
}

const DailyWorkReportDetailsView: FC<DailyWorkReportViewProps> = ({
  journalDailyWorkId,
}) => {
  const { auth } = useAuth()
  const { showSuccess, showError } = useSnackbar()
  const [journalDailyWork, setJournalDailyWork] = useState<JournalDailyWork>()
  const [
    selectedJournalDailyWorkProducts,
    setSelectedJournalDailyWorkProducts,
  ] = useState<string>()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [deletingJournalId, setDeletingJournalId] = useState<number | null>(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  
  const reportMenuOptions: MenuOption[] = [
    {
      name: 'Editar',
      isEnable: journalDailyWork?.canEdit || false,
      action: () => {
        if (journalDailyWork) {
          navigate(`/reporte-trabajo/editar/${journalDailyWork.id}`, {
            state: { currentReport: journalDailyWork },
          })
        }
      },
    },    
    {
      name: 'Eliminar',
      isEnable: journalDailyWork?.canDelete || false,
      action: () => {
        setDeletingJournalId(journalDailyWork?.id || null)
        setOpenDeleteModal(true)
      },
    },
  ]
  
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)

    const getJournalReport = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getJournalDailyWork}${journalDailyWorkId}`,
          { signal: controller.signal }
        )
        setIsLoading(false)
        if (isMounted) {
          setJournalDailyWork(response.data)
        }
      } catch (err: any) {
        setIsLoading(false)
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al obtener el detalle del reporte de trabajo')
        }
      }
    }

    getJournalReport()

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const formatProductDetail = (detail: JournalDailyWorkProduct) => {
      const { productQuantity, productInventory } = detail
      const measureName = productQuantity > 1
          ? `${productInventory.productMeasure.name}s`
          : productInventory.productMeasure.name
      return `${productInventory.name} ${productQuantity} ${measureName}`
    }

    const formattedDetails = journalDailyWork?.journalDailyWorkProducts
      .map(formatProductDetail)
      .join(', ')

    setSelectedJournalDailyWorkProducts(formattedDetails)
  }, [journalDailyWork])

  const handleClickReportMenu = (
    event: React.MouseEvent<HTMLElement>    
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseReportMenu = () => {
    setAnchorEl(null)
  }

  const isModifiedDateGreater = (createdDate: string, modifiedDate: string) => {
    return dayjs(modifiedDate).diff(dayjs(createdDate), 'second') >= 10
  }

  const getPayrollForJournal = async (journalDailyWorkId: number) => {
    const controller = new AbortController()
    setIsLoading(true)
    try {       
      const response = await axiosPrivate.get(
        `${endpoints.getPayrollMasterForJournalDailyWork}${journalDailyWorkId}`, 
        { signal: controller.signal }
      )
      setIsLoading(false)
      const payroll = response.data
      return payroll 
    } catch (err: any) {
      const error = err as AxiosError<ApiError>
      if (error.name !== 'CanceledError') {
        showError('Error consultar la planilla ' + error?.response?.data?.detail)
      }
      setIsLoading(false)
      return null
    }
  } 

  const DeleteJournal = async (journalId: number) => {
    const controller = new AbortController()
    setIsLoading(true)

    const SendDeleteJournalRequest = async () => {
      try {
        const url = endpoints.deleteJournalDailyWork
          .replace('{journalDailyWorkId}', journalId.toString())
          .replace('{deletedByUserEmail}', auth.user?.email || '')

        const response = await axiosPrivate.delete(url, {
          signal: controller.signal,
        })

        if (response.status === 200) {
          showSuccess('Reporte eliminado correctamente!')
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {          
          showError(
            'Error al eliminar el reporte ' + error?.response?.data?.detail
          )
        }
      } finally {
        setIsLoading(false)
        navigate(-1)
      }
    }

    SendDeleteJournalRequest()
  }

  const handleOnConfirmDelete = async () => {
    if (deletingJournalId !== null) {
      const payroll = await getPayrollForJournal(deletingJournalId)
      setOpenDeleteModal(false)
      
      if (payroll) {        
        setOpenConfirmDeleteModal(true)
      } else {        
        await DeleteJournal(deletingJournalId)        
      }
    }
  }

  const handleOnCloseDelete = () => {
    setOpenDeleteModal(false)
  }

  const dialogAcceptButton = {
    text: 'Eliminar',
    action: handleOnConfirmDelete,
  }

  const dialogCancelButton = {
    text: 'Cancelar',
    action: handleOnCloseDelete,
  }

  const handleOnCloseConfirmDelete = () => {
    setOpenConfirmDeleteModal(false)
  }
  
  const handleOnConfirmDeleteWithPayroll = async () => {
    if (deletingJournalId !== null) {
      await DeleteJournal(deletingJournalId)
      setOpenConfirmDeleteModal(false)
      setOpenDeleteModal(false)
    }
  }

  const dialogConfirmDeleteWithPayrollAcceptButton = {
    text: 'Eliminar de todas formas',
    action: handleOnConfirmDeleteWithPayroll,
  }
  
  const dialogConfirmDeleteWithPayrollCancelButton = {
    text: 'Cancelar',
    action: handleOnCloseConfirmDelete,
  }  


  return (
    <Container>
      <Stack
        sx={{ mb: 1 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>        
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate(-1)
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Detalle del reporte de trabajo</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Card>
        <CardContent>
          <Grid size={12} container spacing={1} sx={{ mt: 1, mb: 4 }}>
          {isLoading && <Loading centered={true}/>}
            <Grid
              container
              alignItems='center'
              justifyContent='space-between'
              size={{ xs: 12, md: 6 }}
            >
              <Grid size={{ xs: 11, md: 12 }}>
                <TextField
                  size='small'
                  label='Fecha'
                  value={formatFullDateWithDay(
                    journalDailyWork?.journalDailyWorkDate
                  )}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid size={1}>
                <IconButton
                  aria-label='more'
                  id='long-button'
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup='true'
                  onClick={(e) =>
                    journalDailyWork &&
                    handleClickReportMenu(e)
                  }
                  size='small'
                  sx={{ display: { xs: 'block', md: 'none' } }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems='center'
              justifyContent='space-between'
              size={{ xs: 12, md: 6 }}
            >
              <Grid size={{ xs: 12, md: 11 }} sx={{ mb: { xs: -2, md: 1 } }}>
                <TextField
                  size='small'
                  label='Finca'
                  value={journalDailyWork?.farm.name + ''}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid size={1}>
                <IconButton
                  aria-label='more'
                  id='long-button'
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup='true'
                  onClick={(e) =>
                    journalDailyWork &&
                    handleClickReportMenu(e)
                  }
                  size='small'
                  sx={{ display: { xs: 'none', md: 'block' } }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Menu
              id='long-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseReportMenu}
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
            >
              {reportMenuOptions.map((option) => (
                <MenuItem
                  key={option.name}
                  onClick={() => {
                    handleCloseReportMenu()
                    if (journalDailyWork !== null && option.isEnable) {
                      option.action()
                    }
                  }}
                  disabled={!option.isEnable}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Menu>

            <Grid size={12}>
              <TextField
                size='small'
                id='outlined-read-only-job-types'
                label='Trabajos realizados'
                value={journalDailyWork?.journalDailyWorkFarmJobTypes
                  .map((jobType) => jobType.farmJobType.name)
                  .join(', ')}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                multiline
                maxRows={3}
                InputLabelProps={{
                  shrink: true, // force the label to shrink
                }}
              />
            </Grid>

            {selectedJournalDailyWorkProducts && (
              <Grid size={12}>
                <TextField
                  size='small'
                  id='outlined-read-only-products'
                  label='Productos utilizados'
                  value={selectedJournalDailyWorkProducts}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  multiline
                  maxRows={3}
                  InputLabelProps={{
                    shrink: true, // force the label to shrink
                  }}
                />
              </Grid>
            )}
            {journalDailyWork?.description && (
              <Grid size={12}>
                <TextField
                  size='small'
                  id='outlined-read-only-observations'
                  label='Observaciones'
                  value={journalDailyWork?.description}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  multiline
                  maxRows={3}
                  InputLabelProps={{
                    shrink: true, // force the label to shrink
                  }}
                />
              </Grid>
            )}

            {journalDailyWork?.journalDailyWorkDetails.map(
              (detail, detailIndex) => (
                <Grid
                  container
                  size={12}
                  spacing={0}
                  sx={{ ml: 1 }}
                  key={detailIndex}
                >
                  <Grid size={{ xs: 12, md: 3 }}>
                    <Typography
                      fontWeight='bold'
                      sx={detail.paid ? { backgroundColor: '#e6ffe6' } : {}}
                    >
                      {getFullName(detail.employee)}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 7, md: 3 }}>
                    <Typography
                      sx={detail.paid ? { backgroundColor: '#e6ffe6' } : {}}
                    >
                      {detail.employeeJobType.name}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 2, md: 1 }}>
                    <Typography
                      sx={detail.paid ? { backgroundColor: '#e6ffe6' } : {}}
                    >
                      {fNumber(detail.jobQuantity)}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 3, md: 2 }}>
                    <Typography
                      sx={detail.paid ? { backgroundColor: '#e6ffe6' } : {}}
                    >
                      x {fNumber(detail.jobPrice)}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}
            <Grid size={12} container sx={{ mx: 1, mt: 1, mb: 1 }}>
              <Divider style={{ width: '100%' }} />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant='body2' sx={{ mx: 1 }}>
                Reportado: {formatDateTimeCustom(journalDailyWork?.createdDate)}{' '}
                - {journalDailyWork?.createdByUserEmail}
              </Typography>
            </Grid>

            {isModifiedDateGreater(
              journalDailyWork?.createdDate || '',
              journalDailyWork?.modifiedDate || ''
            ) && (
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant='body2' sx={{ mx: 1 }}>
                  Actualizado:{' '}
                  {formatDateTimeCustom(journalDailyWork?.modifiedDate)} -{' '}
                  {journalDailyWork?.modifiedByUserEmail}
                </Typography>
              </Grid>
            )}

            <Grid size={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
              <Divider style={{ width: '100%' }} />
            </Grid>
          </Grid>

          <DynamicDialog
            openDialog={openDeleteModal}
            dialogTitle='Confirmar eliminación'
            onCloseAction={handleOnCloseDelete}
            buttonAccept={dialogAcceptButton}
            buttonCancel={dialogCancelButton}
          >
            <DialogContentText id='generic-dialog-description'>
              ¿Realmente desea eliminar el reporte de trabajo?
            </DialogContentText>
          </DynamicDialog>
          <DynamicDialog
            openDialog={openConfirmDeleteModal}
            dialogTitle='Confirmar eliminación con planilla asociada'
            onCloseAction={handleOnCloseConfirmDelete}
            buttonAccept={dialogConfirmDeleteWithPayrollAcceptButton}
            buttonCancel={dialogConfirmDeleteWithPayrollCancelButton}
          >
            <DialogContentText id='confirm-delete-with-payroll-description'>
              Este reporte está asociado a una planilla existente. ¿Aún así desea
              continuar con la eliminación?
            </DialogContentText>
          </DynamicDialog>
        </CardContent>
      </Card>
    </Container>
  )
}

export default DailyWorkReportDetailsView
