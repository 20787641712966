import { axiosPrivate } from "../api/axios"
import { useEffect } from "react"
import { useAuth } from "../context/AuthProvider"
import { AxiosError } from 'axios'
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../authConfig"

const useAxiosPrivate = () => {    
    const { auth } = useAuth()
    const { instance } = useMsal()
    const account = instance.getActiveAccount()

     useEffect(() => {   
        
        const getToken = async () => {
          try {
            const response = await instance.acquireTokenSilent({
              ...loginRequest,
              account: account,
            })
            setupInterceptors(response.accessToken)
          } catch (e) {
            console.error('Error al adquirir token de forma silenciosa', e)           
          }
        }

        const setupInterceptors = (accessToken) => {
            const requestIntercept = axiosPrivate.interceptors.request.use(
                async config => {
                    if (!config.headers['Authorization']) {
                        config.headers['Authorization'] = `Bearer ${accessToken}`
                    }
                    return config
                }, (error) => Promise.reject(error)
            )

            const responseIntercept = axiosPrivate.interceptors.response.use(
                response => response,
                async error => {                   
                    return Promise.reject(error)
                }
            )

            return () => {
                axiosPrivate.interceptors.request.eject(requestIntercept)
                axiosPrivate.interceptors.response.eject(responseIntercept)
            }
        }

        getToken()
    }, [account, auth, instance])

    return axiosPrivate
}

export default useAxiosPrivate;
export { AxiosError }