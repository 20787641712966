interface LocalStorageItem {
  value: string
  expiration?: number
}

export const setLocalStorageItem = (prop: string, value: string, expirationInHours?: number) => {
  const item: LocalStorageItem = { value }
  if (expirationInHours) {
    const expirationDate = new Date().getTime() + expirationInHours * 60 * 60 * 1000
    item.expiration = expirationDate
  }
  localStorage.setItem(prop, JSON.stringify(item))
}

export const getLocalStorageItem = (prop: string): string => {
  const itemStr = localStorage.getItem(prop)
  if (!itemStr) {
    return ''
  }

  const item: LocalStorageItem = JSON.parse(itemStr)
  if (item.expiration && new Date().getTime() > item.expiration) {
    localStorage.removeItem(prop)
    return ''
  }

  return item.value
}

export const removeLocalStorageItem = (prop: string): void => {
  localStorage.removeItem(prop)
}